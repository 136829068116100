var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-links"},[(_vm.footerData && _vm.totalColumns)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'Checkout'),expression:"$route.name != 'Checkout'"}],staticClass:"container"},[_c('div',{staticClass:"row q-col-gutter-x-md"},[(_vm.footerData?.enableBusinessDetailsInFooter)?_c('div',{class:_vm.footerData?.colCount === 0
              ? `col-12 col-md-3`
              : `col-12 col-md-${12 / _vm.totalColumns}`},[_c('div',{staticClass:"footer-block"},[_c('h5',[(_vm.theme && _vm.theme.logoURL)?_c('img',{attrs:{"src":_vm.theme.logoURL},on:{"click":_vm.goToHomePage}}):_c('label',{staticClass:"q-my-none logo-title",domProps:{"textContent":_vm._s(_vm.businessConfigurations?.businessName || '')},on:{"click":_vm.goToHomePage}})]),_c('div',{staticClass:"footer-content"},[_c('ul',[_c('li',[_c('a',{staticClass:"flex nowrap",attrs:{"href":`tel:${_vm.businessConfigurations.phone}`}},[_c('q-icon',{staticClass:"col-auto q-mr-sm",attrs:{"name":"phone"}}),_c('span',{staticClass:"col"},[_vm._v(_vm._s(_vm.businessConfigurations?.phone))])],1)]),_c('li',[_c('a',{staticClass:"flex nowrap",attrs:{"href":`mailto:${_vm.businessConfigurations.email}?subject=Customer Support`}},[_c('q-icon',{staticClass:"col-auto q-mr-sm",attrs:{"name":"email"}}),_c('span',{staticClass:"col"},[_vm._v(_vm._s(_vm.businessConfigurations?.email))])],1)]),_c('li',[_c('a',{staticClass:"flex nowrap",attrs:{"href":'find-store'}},[_c('q-icon',{staticClass:"col-auto q-mr-sm",attrs:{"name":"map"}}),_c('span',{staticClass:"col"},[_vm._v("Store Locator")])],1)]),(_vm.storeHasLnsSubscription)?_c('li',[_c('a',{staticClass:"flex nowrap items-center cursor-pointer",attrs:{"clickable":""},on:{"click":_vm.showLNS}},[_c('SvgIcon',{staticStyle:{"margin-right":"8px"},attrs:{"size":"1em","icon":"loyalty-o"}}),_c('span',{staticClass:"col"},[_vm._v("Loyalty Program")])],1)]):_vm._e()])])])]):_vm._e(),(_vm.footerData?.enableGetInTouchInFooter)?_c('div',{class:_vm.footerData?.colCount === 0
              ? `col-12 col-md-3`
              : `col-12 col-md-${12 / _vm.totalColumns}`},[_c('div',{staticClass:"footer-block"},[_c('h5',[_vm._v("Get in Touch")]),_c('div',{staticClass:"footer-content"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":'customer-service'}},[_vm._v("Customer Support")])]),_c('li',[_c('a',{attrs:{"href":`tel:${_vm.businessConfigurations.customerServicePhone}`}},[_vm._v(_vm._s(_vm.businessConfigurations?.customerServicePhone))])]),_c('li',[_c('a',{attrs:{"href":`mailto:${_vm.businessConfigurations.customerServiceEmail}?subject=Customer Support`}},[_vm._v(_vm._s(_vm.businessConfigurations?.customerServiceEmail))])])])])]),(
              _vm.footerData?.enableSocialPlatformsInFooter &&
              _vm.socialPlatforms?.length !== 0
            )?_c('div',{staticClass:"footer-block"},[_c('h5',[_vm._v("Connect With Us")]),_c('div',{staticClass:"footer-content"},[(_vm.socialPlatforms)?_c('ul',{staticClass:"inline-flex footer-social"},_vm._l((_vm.socialPlatforms),function(socialPlatform,index){return _c('li',{key:`${socialPlatform.platform}${index}`,staticClass:"q-mr-sm"},[_c('a',{attrs:{"href":socialPlatform.link,"target":"_blank"}},[_c('SvgIcon',{attrs:{"size":"24px","icon":socialPlatform.icon}})],1)])}),0):_vm._e()])]):_vm._e()]):_vm._e(),_vm._l((_vm.footerData.columns),function(column,index){return _c('div',{key:index,class:`col-6 col-md-${12 / _vm.totalColumns}`},[(column.rows && column.rows.length)?_vm._l((column.rows),function(row,index){return _c('div',{key:`${row.menuID}-${index}`,staticClass:"footer-block"},[_c('h5',{domProps:{"innerHTML":_vm._s(row.menuName)}}),_c('div',{staticClass:"footer-content"},[(row.menuItems && row.menuItems.length)?_c('ul',_vm._l((row.menuItems),function(menuItem,index){return _c('li',{key:`${menuItem.menuID}-${index}`},[(menuItem.type == 'link' && menuItem.link)?_c('a',{attrs:{"href":menuItem.link,"target":"_blank"},domProps:{"innerHTML":_vm._s(menuItem.title)}}):_vm._e(),(menuItem.type == 'page' && menuItem.slug)?_c('router-link',{attrs:{"target":menuItem.openInNew ? '_blank' : '_self',"to":`/pages/${menuItem.slug}`},domProps:{"innerHTML":_vm._s(menuItem.title)}}):_vm._e(),(menuItem.type == 'app-page')?_c('router-link',{attrs:{"target":menuItem.openInNew ? '_blank' : '_self',"to":`/${menuItem.appPageSlug || ''}`},domProps:{"innerHTML":_vm._s(menuItem.title)}}):_vm._e(),(menuItem.type == 'legal' && menuItem.appPageSlug)?_c('router-link',{attrs:{"target":menuItem.openInNew ? '_blank' : '_self',"to":`/pages/legal/${_vm.getPageSlug(
                        menuItem.appPageSlug
                      )}`},domProps:{"innerHTML":_vm._s(menuItem.title)}}):_vm._e(),(menuItem.type == 'text')?_c('p',{domProps:{"innerHTML":_vm._s(menuItem.title)}}):_vm._e(),(menuItem.type == 'text')?_c('small',{domProps:{"innerHTML":_vm._s(menuItem.description)}}):_vm._e()],1)}),0):_vm._e()])])}):_vm._e()],2)})],2)]):_vm._e(),(_vm.businessConfigurations?.showDisclaimer)?_c('DisclaimerInfo',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name != 'Checkout'),expression:"$route.name != 'Checkout'"}]}):_vm._e()],1),_c('div',{staticClass:"footer-copyright"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row items-center"},[_c('div',{staticClass:"col-12 col-md-6"},[_vm._v("\n          © "+_vm._s(new Date().getFullYear())+" FasTraxPOS\n          LLC | Powered by\n          "),_c('a',{staticClass:"companyname",attrs:{"href":"https://goftx.com/","target":"_blank"}},[_vm._v("\n            FasTrax Solutions ")]),_vm._v(" | "),_c('router-link',{staticClass:"cursor-pointer",attrs:{"to":"/footer-links/privacy-policy"}},[_vm._v("PrivacyPolicy")])],1),_c('div',{staticClass:"col-12 col-md-6 text-right"},[_c('SectionWeAccept',{scopedSlots:_vm._u([{key:"skeleton",fn:function(){return [_c('div',{staticClass:"container q-pa-md"},[_c('q-list',{staticClass:"row items-center justify-right",attrs:{"dense":""}},[_c('q-item-label',{staticClass:"col-12 title col-md-auto"},[_c('q-skeleton',{staticClass:"text-subtitle1 q-mr-lg",attrs:{"type":"text","width":"100px","height":"30px"}})],1),_c('q-item',{staticClass:"col-12 paymentAcceptIcons col-md-auto"},_vm._l((6),function(n){return _c('q-skeleton',{key:n,staticClass:"q-mr-sm",attrs:{"type":"rect","height":"32px","width":"50px"}})}),1)],1)],1)]},proxy:true}])})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }